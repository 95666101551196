<template>
  <div class="markets-wrapper st-wrapper">

    <header-search
      placeholder="输入市场名称搜索"
      @search="searchMarket">
      <div class="select-btn" @click="selectRegion">
        <s>{{ search.region }}</s>
        <i></i>
      </div>
    </header-search>

    <ul class="market-items" v-if="markets.length > 0">
      <li
        v-for="item in markets"
        :key="item.mid"
        class="st-item"
        @click="onMarketItem(item)">
        <h3>{{ item.comp_name }}</h3>
        <b>
          <span>查看进场信息</span>
        </b>
      </li>
    </ul>

    <empty
      icon="market"
      text="此区域暂无市场"
      btn-text="重新选择区域"
      height="calc(100vh - 3rem)"
      @btn-tap="selectRegion"
      v-else
    />

    <van-popup
      v-model="popupShow"
      position="bottom"
    >
      <van-picker
        show-toolbar
        :columns="regions"
        @confirm="popupConfirm"
        @cancel="popupCancel"
      />
    </van-popup>

  </div>
</template>

<script>
import HeaderSearch from '../../components/header/search/Index'
import Empty from '../../components/empty/Index'
import { Popup, Picker } from 'vant'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Home',
  components: {
    HeaderSearch,
    Empty,
    [Popup.name]: Popup,
    [Picker.name]: Picker
  },
  data () {
    return {
      popupShow: false,
      regions: ['全部', '广陵区', '邗江区', '江都区', '宝应县', '仪征市', '高邮市'],
      search: {
        region: '全部',
        text: '',
        items: []
      },
      markets: []
    }
  },
  computed: {
    ...mapState(['marketItems'])
  },
  mounted () {
    this.getMarketItems()
  },
  methods: {
    // 获取市场列表
    getMarketItems () {
      this.$api.markets.list().then(res => {
        if (res.data.success) {
          const items = res.data.result
          const yzMarkets = _.compact(_.map(items, item => {
            if (item.address.city === '扬州市' && item.type === 2) {
              return item
            }
          }))
          this.$store.dispatch('setMarketItems', yzMarkets)
          this.markets = yzMarkets
        }
      })
    },

    // 搜索市场
    searchMarket (data) {
      this.search.text = data
      this.search.items = this.marketItems
      if (this.search.region !== '全部') {
        this.search.items = _.compact(_.map(this.marketItems, item => {
          if (item.address.region === this.search.region) {
            return item
          }
        }))
      }
      if (data) {
        this.search.items = _.compact(_.map(this.search.items, item => {
          if (item.comp_name && item.comp_name.indexOf(this.search.text) > -1) {
            return item
          }
        }))
      }
      this.markets = this.search.items
    },

    // 点击选择区域按钮
    selectRegion () {
      this.popupShow = true
    },
    // 确定选择区域
    popupConfirm (value) {
      this.region = value
      this.popupShow = false
      this.search.region = value
      this.searchMarket(this.search.text)
    },
    // 取消选择区域
    popupCancel () {
      this.popupShow = false
    },
    // 点击市场项
    onMarketItem (item) {
      this.$router.push({
        name: 'ShopList',
        query: {
          marketId: item.mid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
